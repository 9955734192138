import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = 'G-ZR4RK04LW9';

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = () => {
  ReactGA.send('pageview');
};