import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import { log, error } from "../utils/log";
import { logPageView } from '../utils/ga';
import { useLocation } from 'react-router-dom';

//images
import backgroundImage from "../images/toile_de_jouy_pattern_2.png";
import sustainability1 from "../images/sustainability1.jpg";
import sustainability2 from "../images/sustainability2.jpg";
import sustainability3 from "../images/sustainability3.jpg";
import sustainability4 from "../images/sustainability4.jpg";
import truck from "../images/art_truck.png";

//chakra ui and framer motion
import {
  ChakraProvider,
  SimpleGrid,
  Heading,
  Text,
  Image,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * function for scroll animation
 * the original position for each element is either -500 or 500 from x axis
 */
const sectionVariants = {
  hidden: (direction) => ({
    transform:
      direction === "left" ? "translateX(-500px)" : "translateX(500px)",
    opacity: 0,
  }),
  visible: {
    transform: "translateX(0)", //apply transfomation to an element to move into the screen across the x axis
    opacity: 1,
    transition: {
      duration: 1.5, //set transition to 1.5s
    },
  },
};

function Sustainability() {
  //Google analytics
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);
  
  /**
   * checks if the window width is greater than or equal to 576 pixels.
   * if it is, the initial value of isSmallOrAbove will be true, indicating that the window is considered "small or above"
   * otherwise, the initial value will be false.
   */
  const [isSmallOrAbove, setIsSmallOrAbove] = useState(
    window.innerWidth >= 576
  );

  useEffect(() => {
    //this function makes sure that the transition only happens when the screen is >= 576px, else it wont animate
    const handleResize = () => {
      setIsSmallOrAbove(window.innerWidth >= 576);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Setup reference (ref1) and state (inView1) that will be true when the referenced element enters the viewport
   * and trigger animation once
   */
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });

  // Create an animation control object to manage animations
  const animation1 = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();

  useEffect(() => {
    // check if the screen size is >= 576px (sm)
    if (isSmallOrAbove) {
      // If the screen size is >= 576px, trigger animations based on the visibility of each element
      if (inView1) animation1.start("visible");
      if (inView2) animation2.start("visible");
      if (inView3) animation3.start("visible");
    } else {
      animation1.start("visible");
      animation2.start("visible");
      animation3.start("visible");
    }
  }, [
    //variables used to track the visibility and animation states of certain elements in the component.
    inView1,
    inView2,
    inView3,
    isSmallOrAbove,
    animation1,
    animation2,
    animation3,
  ]);

  /**
   * for firebase description content
   */

  //green section retrieved from firebase
  const [greenSection, setGreenSection] = useState([]);

  useEffect(() => {
    const fetchGreenSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/sustainability/green`
        );
        setGreenSection(response.data);
      } catch (error) {
        error("Error fetching data:", error);
      }
    };

    fetchGreenSection();
  }, []);

  //local section retrieved from firebase
  const [localSection, setLocalSection] = useState([]);

  useEffect(() => {
    const fetchLocalSection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/sustainability/local`
        );
        setLocalSection(response.data);
      } catch (error) {
        error("Error fetching data:", error);
      }
    };

    fetchLocalSection();
  }, []);

  //philanthrophy section retrieved from firebase
  const [philanthrophySection, setPhilanthrophySection] = useState([]);

  useEffect(() => {
    const fetchPhilanthropySection = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/sustainability/philanthropy`
        );
        setPhilanthrophySection(response.data);
      } catch (error) {
        error("Error fetching data:", error);
      }
    };

    fetchPhilanthropySection();
  }, []);

  // for carousel
  const sliderRef = React.useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: true,
  };

  const isSmallOrLesser = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraProvider>
      {/* Page title */}
      <Helmet>
        <title>Our Sustainability & Community Plan</title>
      </Helmet>

      <NavBar />
      <div className="container-fluid background-container">
        {/* background image */}
        <div
          className="background-overlay"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>

        {/* page content */}
        <div className="container content">
          {/* First row */}
          <motion.div
            // set this motion.div to ref1, animation1
            className="row mb-md-5 pb-md-5 mb-3 pb-3"
            ref={ref1}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation1}
            variants={sectionVariants}
            custom="left" //make this div section enter from the left
          >
            {/* first col */}
            <div className="col-md-5">
              <div className="mb-4 mb-md-5">
                <Heading
                  className="header"
                  fontSize="4xl"
                  sx={{
                    fontFamily: "ZT-kis",
                    fontSize: ["24px", "24px", "36px", "36px"],
                  }}
                >
                  Green
                </Heading>
                {greenSection.length > 0 && (
                  <Text
                    className="subText"
                    fontSize="md"
                    sx={{
                      fontFamily: "Archivo",
                      textAlign: "justify",
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                  >
                    {greenSection[0].field}
                  </Text>
                )}
              </div>

              {greenSection.slice(1).map((section, index) => (
                <Box key={index} className="mb-4 mb-md-5">
                  {section.header && (
                    <Heading
                      className="header"
                      fontSize="3xl"
                      sx={{
                        fontFamily: "ZT-kis",
                        fontSize: ["20px", "20px", "30px", "30px"],
                        opacity: 0.75,
                      }}
                    >
                      {section.header}
                    </Heading>
                  )}
                  <Text
                    className="subText"
                    fontSize="md"
                    sx={{
                      fontFamily: "Archivo",
                      textAlign: "justify",
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                  >
                    {section.field}
                  </Text>
                </Box>
              ))}
            </div>

            <div className="col-md-2"></div>

            {/* images col*/}
            <div className="col-md-5 d-flex justify-content-end">
              {/* carousel */}
              <>
                {isSmallOrLesser ? (
                  <Box position="relative" width="full" overflow="hidden">
                    <Slider ref={sliderRef} {...settings}>
                      <Image
                        style={{
                          height: "300px",
                          width: "500px",
                          marginBottom: "5%",
                        }}
                        src={sustainability1}
                      />
                      <Image
                        style={{
                          height: "300px",
                          width: "500px",
                          marginBottom: "5%",
                        }}
                        src={sustainability2}
                      />
                      <Image
                        style={{ height: "300px", width: "500px" }}
                        src={sustainability3}
                      />
                    </Slider>

                    {/* for the slider dots */}
                    <div className="mt-5"></div>
                  </Box>
                ) : (
                  <SimpleGrid columns={1}>
                    <Image
                      style={{
                        height: "300px",
                        width: "500px",
                        marginBottom: "5%",
                      }}
                      src={sustainability1}
                    />
                    <Image
                      style={{
                        height: "300px",
                        width: "500px",
                        marginBottom: "5%",
                      }}
                      src={sustainability2}
                    />
                    <Image
                      style={{ height: "300px", width: "500px" }}
                      src={sustainability3}
                    />
                  </SimpleGrid>
                )}
              </>
            </div>
          </motion.div>

          {/* Second row */}
          <motion.div
            // set this motion.div to ref2, animation2
            className="row mb-md-5 pb-md-5 mb-3 pb-3"
            ref={ref2}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation2}
            variants={sectionVariants}
            custom="right" //make this div section enter from the right
          >
            {/* image */}
            <div className="col-md-6 d-flex justify-content-md-start order-3 order-md-1">
              <Image
                style={{ width: "auto" }}
                sx={{
                  height: ["300px", "300px", "400px", "400px"],
                }}
                src={sustainability4}
              />
              <div
                className="d-none d-md-inline"
                style={{ height: "550px" }}
              ></div>
            </div>

            <div className="col-md-1 order-2 order-md-2"></div>

            {/* content */}
            <div className="col-md-5 mt-md-0 d-md-flex align-items-md-end order-1 order-md-3">
              <div>
                <Heading
                  className="header text-end"
                  fontSize="4xl"
                  sx={{
                    fontFamily: "ZT-kis",
                    fontSize: ["24px", "24px", "36px", "36px"],
                  }}
                >
                  Philanthropy
                </Heading>

                {philanthrophySection.map((paragraph, index) => (
                  <React.Fragment key={index}>
                    <Text
                      className="subText"
                      fontSize="md"
                      sx={{
                        fontFamily: "Archivo",
                        textAlign: "justify",
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                    >
                      {paragraph.field}
                    </Text>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Third row */}
          <motion.div
            // set this motion.div to ref3, animation3
            className="row mb-5 pb-5 mt-5 mt-mb-0 "
            ref={ref3}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation3}
            variants={sectionVariants}
            custom="left" //make this div section enter from the left
          >
            {/* column */}
            <div className="col-md-5">
              <div className="mb-4 mb-md-5">
                <Heading
                  className="header text-md-center text-start"
                  fontSize="4xl"
                  sx={{
                    fontFamily: "ZT-kis",
                    fontSize: ["24px", "24px", "36px", "36px"],
                  }}
                >
                  Staying Local
                </Heading>

                {localSection.length > 0 && (
                  <Text
                    className="subText"
                    fontSize="md"
                    sx={{
                      fontFamily: "Archivo",
                      textAlign: "justify",
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                  >
                    {localSection[0].field}
                  </Text>
                )}
              </div>

              <div className="mb-4 mb-md-5">
                {localSection.slice(1).map((section, index) => (
                  <Box key={index} className="mb-4 mb-md-5">
                    {section.header && (
                      <Heading
                        className="header"
                        fontSize="3xl"
                        sx={{
                          fontFamily: "ZT-kis",
                          fontSize: ["20px", "20px", "30px", "30px"],
                          opacity: 0.75,
                        }}
                      >
                        {section.header}
                      </Heading>
                    )}
                    <Text
                      className="subText"
                      fontSize="md"
                      sx={{
                        fontFamily: "Archivo",
                        textAlign: "justify",
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                    >
                      {section.field}
                    </Text>
                  </Box>
                ))}
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-6 d-flex justify-content-end">
              <Image
                style={{
                  height: "auto",
                  width: "auto",
                }}
                src={truck}
              />
            </div>
          </motion.div>
        </div>
      </div>

      <Footer />
    </ChakraProvider>
  );
}

export default Sustainability;
