import "../styles/Home.css";
import "../styles/App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import { log, error } from "../utils/log";
import { logPageView } from '../utils/ga';
import { useLocation } from 'react-router-dom';

//images
import { Image } from "react-bootstrap";
import waterfall from "../images/Waterfall.jpg";
import loop from "../images/Closed loop.png";

//links
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import LandingComponent from "../components/LandingComponent";
import CardComponent from "../components/CardComponent";
import NewsComponent from "../components/NewsComponent";

//designs
import {
  ChakraProvider,
  Button,
  Heading,
  SimpleGrid,
  Text,
  Center,
  Flex,
  Box,
  IconButton,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import Slider from "react-slick";
import { motion, useAnimation } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * function for scroll animation
 * the original position for each element is either -500 or 500 from x axis
 */
const sectionVariants = {
  hidden: (direction) => ({
    transform:
      direction === "left" ? "translateX(-500px)" : "translateX(500px)",
    opacity: 0,
  }),
  visible: {
    transform: "translateX(0)", //apply transfomation to an element to move into the screen across the x axis
    opacity: 1,
    transition: {
      duration: 1.5, //set transition to 1.5s
    },
  },
};

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 8000,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    //when breakpoint is 1024px, show 3 cards
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      //when breakpoint is 768px, show 2 cards
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      //when breakpoint is 480px, show 1 card
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Home() {
  //Google analytics
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);

  // As we have used custom buttons, we need a reference variable to change the state
  const [slider, setSlider] = React.useState(null);

  // These are the breakpoints which changes the position of the buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "10px" });

  const [isSmallScreen] = useMediaQuery("(max-width: 576px)");

  /**
   * checks if the window width is greater than or equal to 576 pixels.
   * if it is, the initial value of isSmallOrAbove will be true, indicating that the window is considered "small or above"
   * otherwise, the initial value will be false.
   */
  const [isSmallOrAbove, setIsSmallOrAbove] = useState(
    window.innerWidth >= 576
  );

  useEffect(() => {
    //this function makes sure that the transition only happens when the screen is >= 576px, else it wont animate
    const handleResize = () => {
      setIsSmallOrAbove(window.innerWidth >= 576);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Setup reference (ref1) and state (inView1) that will be true when the referenced element enters the viewport
   * and trigger animation once
   */
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });

  // Create an animation control object to manage animations
  const animation1 = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const animation5 = useAnimation();

  useEffect(() => {
    // check if the screen size is >= 576px (sm) and if the element is in view
    if (isSmallOrAbove) {
      // If the screen size is >= 576px, trigger animations based on the visibility of each element
      if (inView1) animation1.start("visible");
      if (inView2) animation2.start("visible");
      if (inView3) animation3.start("visible");
      if (inView4) animation4.start("visible");
      if (inView5) animation5.start("visible");
    } else {
      animation1.start("visible");
      animation2.start("visible");
      animation3.start("visible");
      animation4.start("visible");
      animation5.start("visible");
    }
  }, [
    //variables used to track the visibility and animation states of certain elements in the component.
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    isSmallOrAbove,
    animation1,
    animation2,
    animation3,
    animation4,
    animation5,
  ]);

  //products firestore
  const [products, setProducts] = useState([]);

  /**
   * Fetches products data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/products`
        );
        setProducts(response.data);
      } catch (error) {
        error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  //news firestore
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); //currentPage is initialized with a value of 1
  const articlesPerPage = 4; //number of articles to be displayed per page

  /**
   * Fetches news data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/news`
        );
        setNews(response.data);
      } catch (error) {
        error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  // Calculate the indices of the news articles to display on the current page
  /**
   * implementing pagination logic
   *  where currentPage represents the current page number and articlesPerPage represents the number of articles to be displayed per page
   */
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage; //calculation determines the index of the first article to be displayed on the current page
  /**
   * extracts a subset of the news array starting from indexOfFirstArticle and ending at indexOfLastArticle.
   * This means that currentNews will contain a subset of articles from the news array that corresponds to the current page
   */
  const currentNews = news.slice(indexOfFirstArticle, indexOfLastArticle);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  /**
   * for Texts
   */

  //our story description section
  const [ourStoryData, setOurStoryData] = useState([]);

  useEffect(() => {
    const fetchourStoryData = async () => {
      try {
        const ourStoryResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/home/our-story`
        );
        setOurStoryData(ourStoryResponse.data);
      } catch (error) {
        error("Error fetching data:", error);
      }
    };

    fetchourStoryData();
  }, []);

  //for products section
  const [productsContent, setProductsContent] = useState("");

  useEffect(() => {
    const fetchProductsContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/home/products`
        );
        const firstDocument = response.data[0]; // Assuming the response is an array of documents

        if (firstDocument && firstDocument.field) {
          setProductsContent(firstDocument.field);
        } else {
          error(
            "content not found or does not have content field:",
            firstDocument
          );
        }
      } catch (error) {
        error("Error fetching landing content:", error);
      }
    };

    fetchProductsContent();
  }, []);

  //for sustainability section
  const [sustainabilityContent, setSustainabilityContent] = useState("");

  useEffect(() => {
    const fetchSustainabilityContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/home/sustainability`
        );
        const firstDocument = response.data[0]; // Assuming the response is an array of documents

        if (firstDocument && firstDocument.field) {
          setSustainabilityContent(firstDocument.field);
        } else {
          error(
            "content not found or does not have content field:",
            firstDocument
          );
        }
      } catch (error) {
        error("Error fetching landing content:", error);
      }
    };

    fetchSustainabilityContent();
  }, []);

  return (
    <ChakraProvider>
      {/* Page title */}
      <Helmet>
        <title>Home</title>
      </Helmet>

      <NavBar />
      <div className="container-fluid p-0 m-0">
        <LandingComponent />

        <br />

        {/* Rest of the content here */}
        <div className="container">
          {/* Our story section */}
          <motion.div
            className="row my-md-1 pb-5 mx-3 mx-md-0 mt-5 mt-mb-0 position-relative"
            ref={ref1}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation1}
            variants={sectionVariants}
            custom="left"
          >
            <div className="col-md-1"></div>
            <div className="col-md-2 d-flex justify-content-start justify-content-md-end z-md-3">
              <Heading
                as="h1"
                size="4xl"
                noOfLines={isSmallScreen ? 2 : 1}
                className="vertical-text header"
                sx={{
                  textOrientation: "mixed",
                  opacity: "50%",
                  fontFamily: "'ZT-Kis'",
                  fontSize: ["36px", "36px", "72px", "72px"],
                }}
              >
                Sai Yok Springs
              </Heading>
            </div>

            <Flex
              className="col-md-4 d-flex justify-content-center custom-image-container"
              align="center" // Centers the image vertically
            >
              <Image
                className="custom-image"
                sx={{
                  fit: "cover",
                }}
                src={waterfall}
              />
            </Flex>

            <div className="col-md-5 storyCol">
              <Heading
                className="header"
                fontSize="4xl"
                sx={{
                  fontFamily: "ZT-kis",
                  fontSize: ["24px", "24px", "36px", "36px"],
                }}
              >
                Our Story
              </Heading>

              {ourStoryData.map((paragraph, index) => {
                if (paragraph.type === "text") {
                  const isLastParagraph = index === ourStoryData.length - 2;
                  const isLinkParagraph = index === ourStoryData.length - 1;

                  // Combine the second last and last paragraph
                  if (
                    isLastParagraph &&
                    ourStoryData[index + 1].type === "link"
                  ) {
                    return (
                      <Text
                        key={index}
                        className="subText"
                        fontSize="md"
                        sx={{
                          textAlign: "justify",
                          fontSize: ["14px", "14px", "16px", "16px"],
                        }}
                      >
                        {paragraph.content}{" "}
                        <a
                          href={ourStoryData[index + 1].link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u>{ourStoryData[index + 1].content}</u>
                        </a>
                        .
                      </Text>
                    );
                  } else if (!isLinkParagraph) {
                    // Render other paragraphs normally
                    return (
                      <Text
                        key={index}
                        className="subText"
                        fontSize="md"
                        sx={{
                          textAlign: "justify",
                          fontSize: ["14px", "14px", "16px", "16px"],
                        }}
                      >
                        {paragraph.content}
                      </Text>
                    );
                  }
                }
                return null;
              })}

              <Link to="/our-story">
                <Button
                  colorScheme="gray"
                  variant="outline"
                  fontFamily="ZT-Kis"
                  fontSize={["md", "xl"]}
                  px={6}
                  py={5}
                  border="1px solid"
                  borderColor="gray"
                  borderRadius="0"
                >
                  Discover More
                </Button>
              </Link>
            </div>
          </motion.div>

          <div className="my-mb-5 pb-md-5"></div>

          {/* Products Section */}
          <motion.div
            className="row my-md-5 pb-5 mx-3 mx-md-0 d-flex justify-content-md-center"
            ref={ref2}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation2}
            variants={sectionVariants}
            custom="right"
          >
            <Heading
              noOfLines={1}
              className=" header"
              fontSize="4xl"
              sx={{
                fontFamily: "ZT-kis",
                opacity: 0.75,
                fontSize: ["24px", "24px", "36px", "36px"],
              }}
            >
              Products
            </Heading>

            <Text
              className="subText"
              fontSize="md"
              sx={{
                textAlign: "justify",
                fontSize: ["14px", "14px", "16px", "16px"],
              }}
            >
              {productsContent}
            </Text>

            <br />

            {/* renders a carousel for products*/}
            <Box
              position="relative"
              height="600px"
              width="full"
              overflow="hidden"
            >
              {/* Left Icon */}
              <IconButton
                aria-label="left-arrow"
                borderRadius="full"
                position="absolute"
                left={side}
                top={top}
                transform="translate(0%, -50%)"
                zIndex={2}
                onClick={() => slider?.slickPrev()}
              >
                <SlArrowLeft />
              </IconButton>

              {/* Right Icon */}
              <IconButton
                aria-label="right-arrow"
                borderRadius="full"
                position="absolute"
                right={side}
                top={top}
                transform="translate(0%, -50%)"
                zIndex={2}
                onClick={() => slider?.slickNext()}
              >
                <SlArrowRight />
              </IconButton>

              <Slider {...settings} ref={(slider) => setSlider(slider)}>
                {/* maps over an array called products and creates a CardComponent for each item in the array */}
                {products.map((product, index) => (
                  <CardComponent key={index} {...product} />
                ))}
              </Slider>
            </Box>

            <Link to="/our-water" className="d-flex justify-content-end">
              <Button
                colorScheme="gray"
                variant="outline"
                fontFamily="ZT-Kis"
                fontSize={["md", "xl"]}
                px={6}
                py={5}
                border="1px solid"
                borderColor="gray"
                borderRadius="0"
              >
                Discover More
              </Button>
            </Link>
          </motion.div>

          <div className="my-mb-5 pb-md-5"></div>

          {/* Mini sustainable & community section */}
          <motion.div
            className="row my-5 mx-3 mx-md-0 d-flex justify-content-md-center"
            ref={ref3}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation3}
            variants={sectionVariants}
            custom="left"
          >
            <div className="row">
              <div className="col-md-1 d-flex justify-content-start justify-content-md-end">
                <Heading
                  as="h1"
                  size="4xl"
                  noOfLines={1}
                  className="vertical-text header"
                  sx={{
                    textOrientation: "mixed",
                    opacity: "50%",
                    fontFamily: "'ZT-Kis'",
                    fontSize: ["36px", "36px", "72px", "72px"],
                  }}
                >
                  Sustainability
                </Heading>
              </div>

              <div className="col-md-4 float-start">
                <Heading
                  className=" header"
                  fontSize="4xl"
                  sx={{
                    fontFamily: "ZT-kis",
                    fontSize: ["24px", "24px", "36px", "36px"],
                  }}
                >
                  Our Sustainability & Community Efforts
                </Heading>

                <Text
                  className="subText"
                  fontSize="md"
                  sx={{
                    textAlign: "justify",
                    fontSize: ["14px", "14px", "16px", "16px"],
                  }}
                >
                  {sustainabilityContent}
                </Text>

                <Link
                  to="/our-sustainability-and-community-plan"
                  className="d-flex justify-content-start"
                >
                  <Button
                    colorScheme="gray"
                    variant="outline"
                    fontFamily="ZT-Kis"
                    fontSize={["md", "xl"]}
                    px={6}
                    py={5}
                    border="1px solid"
                    borderColor="gray"
                    borderRadius="0"
                  >
                    Discover More
                  </Button>
                </Link>
              </div>

              <div className="col-md-7">
                <Image
                  style={{ width: "100%" }}
                  sx={{
                    height: ["300px", "300px", "500px", "500px"],
                  }}
                  src={loop}
                />
              </div>
            </div>
          </motion.div>

          <br />

          {/* News section */}
          <motion.div
            className="row my-5 pb-5 mx-mb-5 mx-3 d-flex justify-content-center"
            ref={ref4}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation4}
            variants={sectionVariants}
            custom="right"
          >
            <div className="text-center">
              <h1 className="header">Seen In The News</h1>
              <hr
                className="mx-auto"
                style={{ width: "35%", border: "1px solid" }}
              />
            </div>

            {/* renders a grid of news articles*/}
            <SimpleGrid columns={[1, 2]} spacing={1}>
              {/* dynamically generate the content of the grid. It maps over an array called news and creates a NewsComponent for each item in the array */}
              {currentNews.map((article, index) => (
                <Center key={index}>
                  <NewsComponent {...article} />
                </Center>
              ))}
            </SimpleGrid>

            {/* Pagination */}
            <Box mt={4} display="flex" justifyContent="center">
              {[...Array(Math.ceil(news.length / articlesPerPage)).keys()].map(
                (number) => (
                  <Button
                    key={number + 1}
                    onClick={() => paginate(number + 1)}
                    mx={1}
                    variant="outline"
                    color="#213954"
                    borderColor="#213954"
                  >
                    {number + 1}
                  </Button>
                )
              )}
            </Box>
          </motion.div>
        </div>
      </div>
      <Footer />
    </ChakraProvider>
  );
}

export default Home;
