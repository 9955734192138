import { useEffect } from 'react';
import React, { StrictMode } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles/App.css";
import "./styles/fonts.css";
import { initGA, logPageView } from './utils/ga';
import ScrollToTop from "./components/ScrollToTop";
import { ChakraProvider } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

//Routings
import { Routes, Route  } from "react-router-dom";
import Home from "./pages/Home";
import OurStory from "./pages/OurStory";
import OurWater from "./pages/OurWater";
import Sustainability from "./pages/SustainabilityAndCommunity";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
import FAQ from "./pages/FAQ";

function App() {
  // Google Analytics
  const location = useLocation(); 
  // Track page views whenever location changes
  useEffect(() => {
    logPageView(); // Log the page view whenever the route changes
  }, [location]); 

  return (
    // routings
    <ChakraProvider>
      <StrictMode>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/our-water" element={<OurWater />} />
            <Route
              path="/our-sustainability-and-community-plan"
              element={<Sustainability />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
      </StrictMode>
    </ChakraProvider>
  );
}

export default App;