import React from "react";
import { ChakraProvider, Box, Image, Link } from "@chakra-ui/react";

const PartnerComponent = ({ image, name, link }) => {
  return (
    <ChakraProvider>
      <Box
        position="relative"
        _hover={{
          "> img": {
            filter: "grayscale(100%)", // Apply black and white filter
            opacity: 1.5,
          },
          "> div": {
            opacity: 1,
          },
          transform: "scale(1.05)",
          transition: "transform 0.3s ease, filter 0.3s ease", // Smooth transition for filter
        }}
      >
        <Image src={image} alt={name} borderRadius="md" />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          p="2"
          textAlign="center"
          borderRadius="md"
          opacity="0"
          zIndex="1"
        ></Box>

        {/* link to make the whole box clickable */}
        <Link
          href={link}
          target="_blank"
          rel="noreferrer"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="2"
        ></Link>
      </Box>
    </ChakraProvider>
  );
};

export default PartnerComponent;