import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import PartnerComponent from "../components/PartnerComponent";
import LocationComponent from "../components/LocationComponent";
import { log, error } from "../utils/log";
import { logPageView } from '../utils/ga';
import { useLocation } from 'react-router-dom';

//chakra ui and framer motion
import {
  ChakraProvider,
  Center,
  SimpleGrid,
  Text,
  Heading,
  Image,
  Box,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//images
import backgroundImage from "../images/toile_de_jouy_pattern_3.png";
import radachFam from "../images/art_RadachFamily.png";

/**
 * function for scroll animation
 * the original position for each element is either -500 or 500 from x axis
 */
const sectionVariants = {
  hidden: (direction) => ({
    transform:
      direction === "left" ? "translateX(-500px)" : "translateX(500px)",
    opacity: 0,
  }),
  visible: {
    transform: "translateX(0)", //apply transfomation to an element to move into the screen across the x axis
    opacity: 1,
    transition: {
      duration: 1.5, //set transition to 1.5s
    },
  },
};

function OurStory() {
  //Google analytics
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);

  /**
   * checks if the window width is greater than or equal to 576 pixels.
   * if it is, the initial value of isSmallOrAbove will be true, indicating that the window is considered "small or above"
   * otherwise, the initial value will be false.
   */
  const [isSmallOrAbove, setIsSmallOrAbove] = useState(
    window.innerWidth >= 576
  );

  useEffect(() => {
    //this function makes sure that the transition only happens when the screen is >= 576px, else it wont animate
    const handleResize = () => {
      setIsSmallOrAbove(window.innerWidth >= 576);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Setup reference (ref1) and state (inView1) that will be true when the referenced element enters the viewport
   * and trigger animation once
   */
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });

  // Create an animation control object to manage animations
  const animation1 = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();

  useEffect(() => {
    // check if the screen size is >= 576px (sm)
    if (isSmallOrAbove) {
      // If the screen size is >= 576px, trigger animations based on the visibility of each element
      if (inView1) animation1.start("visible");
      if (inView2) animation2.start("visible");
      if (inView3) animation3.start("visible");
    } else {
      animation1.start("visible");
      animation2.start("visible");
      animation3.start("visible");
    }
  }, [
    //variables used to track the visibility and animation states of certain elements in the component.
    inView1,
    inView2,
    inView3,
    isSmallOrAbove,
    animation1,
    animation2,
    animation3,
  ]);

  const [partners, setPartners] = useState([]);

  /**
   * Fetches FAQs data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/partners`);
        setPartners(response.data);
      } catch (error) {
        error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, []);

  const [locations, setLocations] = useState([]);

  /**
   * Fetches FAQs data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/locations`);
        setLocations(response.data);
      } catch (error) {
        error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  /**
   * for firebase description content
   */
  const [ourStoryData, setOurStoryData] = useState([]);

  useEffect(() => {
    const fetchOurStoryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/our-story/who-we-are`
        );
        setOurStoryData(response.data);
      } catch (error) {
        error("Error fetching data:", error);
      }
    };

    fetchOurStoryData();
  }, []);

  // for carousel
  const sliderRef = React.useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
  };

  const isSmallOrLesser = useBreakpointValue({ base: true, md: false });

  // These are the breakpoints which change the position of the buttons as the screen size changes
  const side = useBreakpointValue({ base: "10px", md: "10px" });

  return (
    <ChakraProvider>
      {/* Page title */}
      <Helmet>
        <title>Our Story</title>
      </Helmet>

      <NavBar />
      <div className="container-fluid background-container">
        {/* background image */}
        <div
          className="background-overlay"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>

        {/* page content */}
        <div className="container content">
          {/* story */}
          <motion.div
            className="row mb-5 pb-md-5"
            ref={ref1}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation1}
            variants={sectionVariants}
            custom="left"
          >
            <div className="col-md-7">
              <Heading
                className="header"
                fontSize="4xl"
                sx={{
                  fontFamily: "ZT-kis",
                  fontSize: ["24px", "24px", "36px", "36px"],
                }}
              >
                Who We Are
              </Heading>

              {/* carousel */}
              {isSmallOrLesser ? (
                <Box position="relative" width="full" overflow="hidden">
                  <Slider ref={sliderRef} {...settings}>
                    {ourStoryData.map((paragraph, index) => (
                      <Text
                        className="subText"
                        fontSize="md"
                        sx={{
                          textAlign: "justify",
                          fontSize: ["14px", "14px", "16px", "16px"],
                        }}
                      >
                        {paragraph.field}
                      </Text>
                    ))}
                  </Slider>

                  {/* Carousel icons div */}
                  <div className="mt-5">
                    {/* Left Icon */}
                    <IconButton
                      aria-label="left-arrow"
                      borderRadius="full"
                      position="absolute"
                      left={side}
                      bottom="10px" // place it above the dots
                      zIndex={2}
                      onClick={() => sliderRef.current.slickPrev()}
                    >
                      <SlArrowLeft />
                    </IconButton>

                    {/* Right Icon */}
                    <IconButton
                      aria-label="right-arrow"
                      borderRadius="full"
                      position="absolute"
                      right={side}
                      bottom="10px" // place it above the dots
                      zIndex={2}
                      onClick={() => sliderRef.current.slickNext()}
                    >
                      <SlArrowRight />
                    </IconButton>
                  </div>
                </Box>
              ) : (
                // display normal text when screen is above 576px
                <>
                  {ourStoryData.map((paragraph, index) => (
                    <Text
                      className="subText"
                      fontSize="md"
                      sx={{
                        textAlign: "justify",
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                    >
                      {paragraph.field}
                    </Text>
                  ))}
                </>
              )}
            </div>

            <div className="col-md-5">
              <Box
                className="mt-5 mt-mb-0"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  src={radachFam}
                  sx={{
                    height: ["400px", "400px", "500px", "500px"],
                  }}
                />
              </Box>
            </div>
          </motion.div>

          {/* where to find us */}
          <motion.div
            className="row mb-md-5 pb-md-5 mb-5"
            ref={ref2}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation2}
            variants={sectionVariants}
            custom="right"
          >
            <Heading
              className="header text-md-center text-start"
              fontSize="4xl"
              sx={{
                fontFamily: "ZT-kis",
                fontSize: ["24px", "24px", "36px", "36px"],
              }}
            >
              Where To Find Us
            </Heading>
            <Text
              className="subText mb-5"
              fontSize="md"
              sx={{
                textAlign: ["justify", "justify", "center", "center"],
                fontSize: ["14px", "14px", "16px", "16px"],
              }}
            >
              We partner with a curated selection of restaurants, including
              Michelin-starred establishments, luxury hotels, and members'
              clubs, ensuring exceptional service and experiences.
            </Text>

            {/* Location and graphic map */}
            <div className="mb-5">
              <SimpleGrid
                columns={locations.length < 3 ? [2, 2, 2, 2] : [2, 2, 3, 3]}
                spacing="8%"
                justifyContent={locations.length < 3 ? "center" : "flex-start"}
                mb={10} // Add margin-bottom to ensure space between sections
              >
                {locations.map((location, index) => (
                  <Center key={index}>
                    <LocationComponent {...location} />
                  </Center>
                ))}
              </SimpleGrid>
            </div>
          </motion.div>

          {/* partners */}
          <motion.div
            className="row mb-5 pb-5"
            ref={ref3}
            initial={isSmallOrAbove ? "hidden" : "visible"}
            animate={animation3}
            variants={sectionVariants}
            custom="left"
          >
            <Heading
              className="header text-start mb-4"
              fontSize="4xl"
              sx={{
                fontFamily: "ZT-kis",
                fontSize: ["24px", "24px", "36px", "36px"],
              }}
            >
              Partners
            </Heading>

            {/*  Renders client logo by inserting the data through the PartnerComponent */}
            {/* Also acts as a for loop for each data */}
            <SimpleGrid columns={[3, 3, 4, 4]} spacing="40px">
              {partners.map((partner, index) => (
                <PartnerComponent key={index} {...partner} />
              ))}
            </SimpleGrid>
          </motion.div>
        </div>
      </div>

      <Footer />
    </ChakraProvider>
  );
}

export default OurStory;
