import "../styles/Navbar.css";
import React, { useEffect, useState } from "react";
import logo1 from "../images/logo_dark_saiyok_small.png";
import logo2 from "../images/sys_logo_blue.png";
import { Link } from "react-router-dom";

const NavigationBar = () => {
  /**
   *  isScrolled, which is initialized with a value of false and is used to track whether the user has scrolled the page or not. 
   * It can be updated later in the component's code using the setIsScrolled function.
   */
  const [isScrolled, setIsScrolled] = useState(false);
  /**
   * is isLogoTransitioning, also initialized with a value of false
   * This variable might be used to indicate whether the logo in the navbar is currently transitioning or not. 
   * It can be updated using the setIsLogoTransitioning function.
   */
  const [isLogoTransitioning, setIsLogoTransitioning] = useState(false);
  const [logo, setLogo] = useState(logo1); // Initialize logo state with logo1

  useEffect(() => {
    let timeoutId; // Initialize timeoutId variable

    const handleScroll = () => {
      clearTimeout(timeoutId);
      //clearTimeout function is used to clear any existing timeout before setting a new one

      //set new timeout
      timeoutId = setTimeout(() => {
        const distanceY = window.scrollY || document.documentElement.scrollTop; //calculates the distance that the page has been scrolled vertically
        const shrinkOn = 100;//threshold value of 100px
        // when the page is scrolled beyond 100 pixels, certain actions will be triggered.

        /**
       * checks if the distanceY variable is greater than shrinkOn threshold value
       * 
       */
        if (distanceY > shrinkOn) {
          /**
         * if the page has been scrolled and the isScrolled state is false, the setIsScrolled function is called to update the state to true
         * which means the user has scrolled the page and the navbar should be shrunk and logo should be transitioned
         */
          if (!isScrolled) {
            setIsScrolled(true);
            setIsLogoTransitioning(true);

            setTimeout(() => {
              setLogo(logo2);
              setIsLogoTransitioning(false);
            }, 50); // Delay for logo change
          }
        } else {
          /**
         * if the page has been scrolled and the isScrolled state is true, the setIsScrolled function is called to update the state to false
         * the logo should be transitioned back to the original logo.
         */
          if (isScrolled) {
            setIsScrolled(false);
            setIsLogoTransitioning(true);

            setTimeout(() => {
              setLogo(logo1);
              setIsLogoTransitioning(false);
            }, 30); // Delay for logo change
          }
        }
      }, 100); // Adjust debounce delay as needed
    };

    window.addEventListener('scroll', handleScroll);

    //leanup function that is used to remove event listeners and clear timeout
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${isScrolled ? 'shrunk' : 'transparent'}`}>

      {/* hamburger menu appears when screen is smaller than 576px (sm) */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* navigation links */}
      <div className="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
        <ul className="navbar-nav w-100 d-flex justify-content-around align-items-center">
          <li className="nav-item flex-fill text-center">
            <Link to="/our-story">
              <div className="nav-link">Our Story</div>
            </Link>
          </li>
          <li className="nav-item flex-fill text-center">
            <Link to="/our-water">
              <div className="nav-link">Our Water</div>
            </Link>
          </li>
          <li className="nav-item mx-auto text-center">
            <Link to="/">
              <img
                id="logo"
                src={logo}
                alt="Sai Yok Springs Logo"
                className={`logo ${isLogoTransitioning ? 'transitioning' : ''} ${isScrolled ? 'logoShrink' : ''}`}
              />
            </Link>
          </li>
          <li className="nav-item ms-md-5 justify-content-md-end">
            <Link to="/our-sustainability-and-community-plan">
              <div className="nav-link">Our Sustainability & Community Plan</div>
            </Link>
          </li>
          <li className="nav-item flex-fill text-center">
            <Link to="/contact-us">
              <div className="nav-link">Contact Us</div>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;