import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  WrapItem,
  Center,
  VStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Text,
} from "@chakra-ui/react";

const LocationComponent = ({ image, location }) => {
  //modal popup for the graphic maps so that it can be clicked and enlarged
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalImage, setModalImage] = useState("");

  //handles the opening of a modal and displaying an image
  const openModal = (src) => {
    setModalImage(src);
    onOpen();
  };

  return (
    <ChakraProvider>
      <Box>
        <WrapItem>
          <Center>
            <VStack spacing={2}>
              <Box  sx={{
                    height: ["55px", "65px", "75px", "85px"],
                    textAlign: "center"
                  }}>
                    
                <Text
                  className="header"
                  sx={{
                    fontSize: ["20px", "24px", "30px", "36px"],
                  }}
                >
                  {location}
                </Text>
              </Box>
              <Image
                src={image}
                alt={location}
                sx={{
                  height: ["300px", "300px", "500px", "600px"],
                  width: ["300px", "300px", "400px", "400px"],
                  cursor: "pointer",
                }}
                onClick={onOpen}
              />

              {/* Modal for Full-size Image */}
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalBody
                    justifyContent="center"
                    alignItems="center"
                    className="p-5"
                  >
                    <Image src={image} height="600px" />
                  </ModalBody>
                </ModalContent>
              </Modal>
            </VStack>
          </Center>
        </WrapItem>
      </Box>
    </ChakraProvider>
  );
};

export default LocationComponent;