import "../styles/Faq.css";
import React from "react";
import {
    ChakraProvider,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
} from "@chakra-ui/react";
import DOMPurify from 'dompurify';

//inserts question and response to the faq component to render the data
const FaqComponent = ({question, response}) => {
  
  // replace \n in the response string with <br><br> to create new lines
  const formattedResponse = response.replace('\\n', '<br><br>');

  // Sanitise the HTML content
  const sanitisedResponse = DOMPurify.sanitize(formattedResponse);

  return (
    <ChakraProvider>
          <AccordionItem>
            
                {/* header here */}
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                  bg="#213954"
                  color="white"
                  _hover={{ bg: "#172c44" }}
                >
                  <Text fontSize="md" sx={{ fontFamily: "Archivo", textAlign: "start",
                    fontSize: ["14px", "14px", "16px", "16px"]
                   }}>
                    {question}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>

                {/* response here */}
                <AccordionPanel pb={4} bg="white" color="black">
                  <Text
                    color="gray.600"
                    fontSize="md"
                    sx={{ fontFamily: "Archivo",
                      textAlign: "justify",
                      fontSize: ["14px", "14px", "16px", "16px"]
                     }}
                     dangerouslySetInnerHTML={{ __html: sanitisedResponse }}
                    />
                </AccordionPanel>

              </AccordionItem>
    </ChakraProvider>
  );
};

export default FaqComponent;